@tailwind base;
@tailwind components;
@tailwind utilities;

.menu-enter {
  transform: translateX(100%);
}
.menu-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.menu-exit {
  transform: translateX(0);
}
.menu-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.scroll-arrow {
  animation: bounce 2s infinite;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
